import Vue from 'vue';

export default Vue.extend({
  name: 'PanoramicVideo',

  props: {
    statusPanoramic: {
      type: Boolean,
      required: true
    },
    mediaUrl: {
      type: String,
      required: true
    },
    mediaId: {
      type: String,
      required: true
    }
  },

  data: () => ({}),

  mounted() {
    if (this.statusPanoramic) window.addEventListener('load', this.renderImage());
  },

  methods: {
    renderImage() {
      /* eslint-disable */
      pannellum.viewer(this.mediaId, {
        type: 'equirectangular',
        panorama: `https://cors-anywhere.herokuapp.com/${this.mediaUrl}`,
        autoLoad: true,
        autoRotate: -6,
        autoRotateInactivityDelay: 500,
        compass: false
      });
    },
  }
});
